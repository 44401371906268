import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ShopifyLogo from "../../../components/images/shopify"
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import SectionNavigate from '../../../components/widgets/sectionNavigate';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyFacturacionGlobal: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <ShopifyLogo />
            </div>
            <h2>
                Facturas Globales <br />
                <span className="small"> (A mostrador)</span>
            </h2>
            <h3>
                Generar factura global manualmente
            </h3>
            <p>
                Todas las facturas que no fueron reclamadas por los clientes y han sido pagadas, deben (idealmente) ser facturadas al final del periodo. <br />
                Los periodos pueden ser: diario, semanal o mensual. Siendo mensualmente el periodo más común a usar.
            </p>
            <p>
                <b>Puedes entrar a facturas globales desde la sección de "Mis pedidos"</b>
            </p>
            <ImageFooted explanation={`Recordatorio de instrucciónes para facturar, seleccióna Ok, entendido si deseas ignorar el recordatorio a futuro.`}>
                <IMG_Shopify file={'billGlobalStart'} />
            </ImageFooted>
            <ImageFooted explanation={`La sección esta dividida en dos bloques, la izquierda muestra el selector de pedidos, el cual te permite filtrar, incluír y excluir pedidos según desees. La derecha muestra el grupo de las facturas según fueron agrupadas (mes, semana, dia).`}>
                <IMG_Shopify file={'billGlobalComplete'} />
            </ImageFooted>
            <p>
                El sistema de facturación global está adecuado para trabajar hasta con decenas de miles de pedidos a la vez, <b>mientras más pedidos tengas, más puede tardar en cargar</b>, el promedio de espera para cargar es de 3000 pedidos / 10 segundos, por lo que hay que ser pacientes. Una vez cargados los pedidos, ya no tendrás que volver a esperar. <br />
            </p>
            <p>
                <b>Cada bloque agrupado por fecha</b> contendrá los pedidos, mostrados en listas de 30 elementos a la vez, usa los filtros en cada bloque o del selector de pedidos de la izquierda para identificar los pedidos que deseas incluir o excluir.
            </p>
            <p>
                Una vez emitida la factura, <b>la fecha de la última facturación <span className="error">se actualizará con la fecha del pedido mas nuevo facturado</span> y será por default la fecha de inicio de la siguiente factura.</b> <br />
                Si deseas cargar pedidos anteriores al último ciclo de facturación, utiliza el selector de "cambiar mes de facturación" para escoger hast dos meses atrás.
            </p>
            <h3>
                Facturas globales automáticas
            </h3>
            <p>
                El proceso automático busca reducir el tiempo que tengas que invertir para ordenar tus facturas a fin de mes.<br />
                Se generará una factura a mostrador con las ventas pagadas que no hayan sido facturadas al igual que emitirla manualmente, solamente que no requiere tu atención.
            </p>
            <p>
                <b>Este proceso corre a las 12:02AM del primer dia de cada mes (e.g. 1ero de Septiembre 12:02AM), pero la factura se genera con fecha de las 11:30PM del último dia (e.g. 31 de Agosto 11:30PM)</b>
            </p>
            <p>
                Todo cliente que compre los ultimos dos dias del mes, podrán obtener su factura hasta el 2 del siguiente mes aunque el pedido ya esté incluído en una factura global, ya que por ley se debe otorgar de 2 a 3 dias para que obtengan su factura. <br/>
                <b>En este caso, el sistema emite una nota de crédito y la factura a tu cliente automáticamente.</b> <br />
                La nota de crédito se hace contra la factura global por el valor total de la factura del cliente. <br />
            <p>
            </p>
                El resultado final generará la factura global (GB), factura al cliente (ECOM ó SNG) y la nota de crédito (REF). <br />
                Este proceso puede iniciarlo tu cliente desde tu página de facturación o puedes iniciarlo tu desde la vista de "Mis pedidos", entrando a facturación manual.
            </p>
            <ImageFooted explanation={`Para activar la facturación global automática, entra a "Mi configuración" y configura este bloque.`}>
                <IMG_Shopify file={'billGlobalConfigure'} />
            </ImageFooted>
            <SectionNavigate previous={`/ecommerce/shopify/facturacion-multiple`} next={`/ecommerce/shopify/facturacion-reembolso`}/>
        </div>
    </Layout>
)

export default ShopifyFacturacionGlobal;